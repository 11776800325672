import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
  state: () => ({
    token: null,
    name: null,
    userId: null,
    isSuperAdmin: null,
  }),
});
