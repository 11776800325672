import Ajv from 'ajv';
import addFormats from 'ajv-formats';

const ajv = new Ajv({ allErrors: true , keywords: ["errorMessages"]}); // options can be passed, e.g. {allErrors: true}
addFormats(ajv);

const validateSchema = (schema, input) => {
  const validate = ajv.compile(schema);
  if (!validate(input)) {
    // ErrorMessages for YNT (ChassisNumber, VinCode and salesRepEmail) support
    const checkVinCode = validate.errors.find(err => err.instancePath === '/vinCode');
    const checkChassisNumber = validate.errors.find(err => err.instancePath === '/chassisNumber');
    const dependencies = validate.errors.filter(err => err.keyword === 'dependencies');

    validate.errors = validate.errors.filter(err => 
      !(err.schemaPath.includes('/oneOf') && (err.message === 'must be null' || err.message === 'must NOT be valid'))
    );
    validate.errors = validate.errors.filter(err => err.keyword !== 'oneOf');
    
    if (checkVinCode) {
      checkVinCode.message = schema.errorMessages.vinCode;
    }
    if (checkChassisNumber) {
      checkChassisNumber.message = schema.errorMessages.chassisNumber;
    }
    if (dependencies.length) {
      dependencies.forEach((err) => {
        err.message = `must have property ${err.params?.missingProperty} when property ${err.params?.property} is present`
      });
    }
    return {
      valid: false,
      errors: ajv.errorsText(validate.errors),
    };
  }
  return { valid: true };
};

export default {
  validateSchema,
};
