import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { addTheme, defineCustomElements } from '@scania/components';
import { theme as scania } from '@scania/theme-light';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import App from './App.vue';
import keycloakAuth from './plugins/keycloakAuth';
import router from './router';
import { useMainStore } from './store';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
library.add(faSpinner);


defineCustomElements();
addTheme(scania);

const app = createApp(App)

app.component('FontAwesomeIcon', FontAwesomeIcon);

app.use(createPinia());
app.use(keycloakAuth);

const kcInitOptions = {
  onLoad: 'login-required',
  pkceMethod: 'S256',
  checkLoginIframe: false,
};

const superAdminRole = process.env.VUE_APP_LS_KEYCLOAK_SUPER_ADMIN_ROLE;
const frontendClient = process.env.VUE_APP_LS_KEYCLOAK_MAIN_FRONTEND_CLIENT;

const loadData = (keycloak) => {
  console.log('loadData auth');
  const store = useMainStore();
  store.token = keycloak.token;
  if (keycloak.idToken) {
    console.log('IDToken');
    store.name = keycloak.idTokenParsed?.name;
    store.userId = keycloak.idTokenParsed?.preferred_username;
    console.log(keycloak)
    if(keycloak.hasResourceRole(superAdminRole, frontendClient)) { 
      store.isSuperAdmin = true 
    }
  }
};
app.config.globalProperties.$keycloak
  .init(kcInitOptions)
  .then((auth) => {
    if (!auth) {
      console.log('Not authenticated');
    } else {
      console.log('Authenticated');
      loadData(app.config.globalProperties.$keycloak);
    }
  }).catch(function () {
    console.log('failed to initialize');
  }).finally(() => {
    app.use(router.createRouter(app));
    app.mount('#app');
  });
