import axios from 'axios';
import { useMainStore } from '../store/index';
import { RequestAddFile, RequestFilesByUser, RequestFileDetails } from '../types/type-api';

const api = axios.create({
  baseURL: process.env.VUE_APP_LS_API_URL,
});

api.interceptors.request.use((config) => {
  const store = useMainStore();
  config.headers.Authorization = store.token;
  return config;
}, (error) => {
  return Promise.reject(error);
});

const addFile = async (fileRequest : RequestAddFile) => api.post('addFile', fileRequest);
const getFilesByUser = async (fileRequest : RequestFilesByUser) => api.get('filesByUserTime', { params: fileRequest });
const getFileDetails = async (fileDetailsRequest : RequestFileDetails) => api.get('rowDetailsByFile', { params: fileDetailsRequest });

export default {
  addFile,
  getFilesByUser,
  getFileDetails,
};
