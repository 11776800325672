import Keycloak from 'keycloak-js';
import { App } from 'vue';

const keycloakUrl = process.env.VUE_APP_LS_KEYCLOAK_URL;

const kcOptions = {
  url: `${keycloakUrl}/auth`,
  realm: 'scania',
  clientId: 'tf-ls-import-frontend',
};

const _keycloak = new Keycloak(kcOptions);

export default {
  install: (app: App, options) => {
    app.config.globalProperties.$keycloak = _keycloak;
    Object.defineProperties(app.config.globalProperties, {
      $keycloak: {
        get() {
          return _keycloak;
        },
      },
    });
    app.provide('keycloak', _keycloak);
  },
};
