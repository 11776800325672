import { createRouter as createVueRouter, createWebHistory } from 'vue-router';
import FileHistoryView from '../views/FileHistoryView.vue';
import FileView from '../views/FileView.vue';
import HomeView from '../views/HomeView.vue';

const requiredRole = process.env.VUE_APP_LS_KEYCLOAK_REQUIRED_ROLE;
const frontendClient = process.env.VUE_APP_LS_KEYCLOAK_MAIN_FRONTEND_CLIENT;

const isAuthorized = (app) => {
  //todo set flags for different role in store
  return app.config.globalProperties.$keycloak.authenticated
    && app.config.globalProperties.$keycloak.hasResourceRole(requiredRole, frontendClient);
};

const createRouter = (app) => {
  const router = createVueRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { top: 0 };
      }
    },
    routes: [{
      path: '/',
      name: 'Home',
      component: HomeView,
      beforeEnter: (to, from) => {
        if (isAuthorized(app)) {
          return ({ name: 'FileView' });
        }
      },
    },
    {
      path: '/file',
      name: 'FileView',
      component: FileView,
      beforeEnter: (to, from) => {
        if (!isAuthorized(app)) {
          return ({ name: 'Home' });
        }
      },
    },
    {
      path: '/history',
      name: 'FileHistoryView',
      component: FileHistoryView,
      beforeEnter: (to, from) => {
        if (!isAuthorized(app)) {
          return ({ name: 'Home' });
        }
      },
    },],
  });

  return router;
};

export default {
  createRouter,
};
